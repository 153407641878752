/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */
import moment from 'moment';

let sysLoggingEnabled = false;

export class LogUtilities {
  initLogger(loggingEnabled) {
    sysLoggingEnabled = loggingEnabled;
  }

   alert(msg) {
    if (sysLoggingEnabled) {
      alert(msg);
    } else {

      const dateStr = this.getCurrentDateTime();
      console.log(dateStr, `ERROR (ALERT):  ${msg}`);
    }
  }


  // eslint-disable-next-line 
   log(...logData) {
    if (! sysLoggingEnabled) { return }

    const dateStr = this.getCurrentDateTime();
    if (logData.length === 1) {
      console.log(dateStr, logData[0]);
    } else {
      console.log(dateStr, logData);
    }
  }

   logRESTApi(cmd, action, params) {
    if (action === 'login') {
        this.log('Logging in.....');
        return;
    }

    if (params && params.metrics) {
        this.log([cmd + ' action: ' + action + ';\n   metrics: ' + params.metrics + '\n    params: ', params]); 

    } else {
        this.log([cmd + ' action: ' + action + ';\n    params: ',  params]);
    }
  }

   getCurrentDateTime() {
    return moment().format('MM/DD/YY hh:mm:ssa');
  }
}

const logger = new LogUtilities();
// console.log(sysLoggingEnabled)

export default logger;

/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChangePassword from './ChangePwd';
import AccountDetails from './AccountDetail'
import { AccountStrings as Strs } from '../shared/strings';

const Acct = (params) => {
    const pageName = params.page ? params.page : 'account';
    let [page, setCurrentPage] = useState(pageName.page);
    
    const pages = {
        account: <AccountDetails />,
        changePassword: <ChangePassword />
    }
    
    let currentPage = pages[page] !== undefined ? pages[page] : AccountDetails;

    const buildNavItem = (linkName, linkText) => {
        const selectedCss = (linkName === page) ? 'selected' : '';

        return (
            <div className="nav-item-container">
                <div className={'navItem ' + selectedCss}>
                    <a className={selectedCss} onClick={() => {
                        if (selectedCss.length === 0) {
                            setCurrentPage(linkName)
                        }
                    }}>{linkText}</a>
                </div>
            </div>
        )
    }

    return (<>
            <div className="page-with-nav">
                <div className="side-nav">
                    {buildNavItem('account', Strs.account)}
                    {buildNavItem('changePassword', Strs.changePassword) }
                </div>
                <div className="nav-page page-nav-content">
                    {(currentPage)}
                </div>
            </div>
            </>
    );
}

Acct.propTypes = {
    pageName: PropTypes.string
}

export default Acct;

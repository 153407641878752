/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */
import * as React from 'react';
import { AccountStrings as Strs } from '../shared/strings';
import CaregiverServices from "../DataService/CareGiverService";

const AccountDetails = () => {
    return (<>
        <div className="page-padding">
            <h3>{Strs.account}</h3>
        </div>
        <div className="page-padding nav-page-content">
            <div className="nav-page-body">
                <table className=" new-account-info">
                    <tbody>
                    <tr>
                        <td>{Strs.username}</td>
                        <td>{CaregiverServices.getUsername()}</td>
                    </tr>
                    <tr>
                        <td>{Strs.organization}</td>
                        <td>{CaregiverServices.getOrganizationName()}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}

export default AccountDetails;

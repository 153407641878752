/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */
import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import Utils from '../shared/Utilities'

import PasswordControl from '../shared/PasswordCtrl';

import PatientDataStorage   from '../DataService/DataService';
import CaregiverServices from '../DataService/CareGiverService';

import { AccountStrings as Strs, AppStrings } from '../shared/strings';
import {URLS} from '../shared/urls';
// import logger from '../shared/logUtilities';
import { passwordChangeTimeout } from '../shared/constants';

const ChangePassword = () => {
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [changePwdError, setChangePwdError] = useState(false)
    const [newPwdCurrentPwdTheSameError, setNewPwdCurrentPwdTheSameError] = useState(false);
    const handleChangePassword = (pInfo) => {
        // logger.log(`old Pwd = ${pInfo.currentPwd}; password = ${pInfo.newPwd}; valid = ${pInfo.valid ? 'true' : 'false'}; confirmPwd = ${pInfo.confirmPwd}`);

        if (pInfo.currentPwd === pInfo.newPwd) {
            setNewPwdCurrentPwdTheSameError(true)
        } else {
            PatientDataStorage.changePassword(pInfo.currentPwd, pInfo.newPwd, pwdChangeCallback)
        }
    }

    const pwdChangeCallback = (status) => {
        switch (status) {
            case 0:
                CaregiverServices.logout();
                setPasswordHasChanged(true);
                setTimeout(() => {
                    Utils.cleanupGoToLogin(history, false); 
                }, passwordChangeTimeout)

                break;
            case -1: 
                Utils.cleanupGoToLogin(history, true);
                break;
            case -2: 
                setChangePwdError(true)
                break;
            default:

        }
    }

    const buildModal = () => {
        return (
            <div className="static-modal">
                <Modal show={true} backdrop={true}>
                    <Modal.Header>
                        <Modal.Title>{Strs.pwdChangedModalTitle}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {Strs.pwdChangedModalBody}<br /><br />
                        {Strs.pwdChangedModalBody1()}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => {window.location.replace(URLS.logout)}} variant="primary">{Strs.pwdGoToLogin}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    const dupPwdModal = () => {
        let showModal = true;
        const onOk = () => {
            showModal = false;
            setNewPwdCurrentPwdTheSameError(false);
        }
        return (
            <div className="static-modal">
            <Modal show={showModal} backdrop={true}>
                <Modal.Header>
                    <Modal.Title>{Strs.pwdChangedModalTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Strs.pwdRuleNewPwdNotOldPwd}
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => {onOk()}} variant="primary">{AppStrings.ok}</Button>
                </Modal.Footer>
            </Modal>
        </div>
        )
    }

    let pwdChangedModal = <div></div>;
    if (passwordHasChanged) {
        pwdChangedModal = buildModal();
    } else if (newPwdCurrentPwdTheSameError) {
        pwdChangedModal =  dupPwdModal(); 
    }
    const pwdErrorText = changePwdError ? <><br/><div className="mpyError">{Strs.pwdError}</div><br/></> : <div></div>;

    return (
        <div className="nav-page">
            <div className="page-padding">
                <h3>{Strs.changePassword}</h3>
            </div>
            {pwdErrorText}
            <div className="nav-page-content">
                <div className="nav-page-body">
                    <div className="page-padding chng-pwd-top-padding">
                        <PasswordControl currentPassword="" promptForCurrentPassword={true} callback={(pInfo) => handleChangePassword(pInfo) }/>
                    </div>
                </div>
            </div>
            {pwdChangedModal}
        </div>
    );
}

export default ChangePassword;

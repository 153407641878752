/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */
import * as React from 'react';

import { AccountStrings as Strs } from './strings';

// tslint:disable-next-line: variable-name
const PwdValidationCtrl = (validation, promptForCurrentPassword) => {
    const valState = validation.valState;
    const bckgrndCss = validation.background ? validation.background : 'panel-bgd';

    const redIcon = () => {
      return (<span className="red"><i className="fas fa-exclamation" aria-hidden="true"></i></span>); 
    }
    const greenIcon = () => {
      return (<span className="green"><i className="fas fa-check" aria-hidden="true"></i></span>); 
    }
    
    let currentPwd = <div></div>;
    if (promptForCurrentPassword) {
      const hasCurrentPwd = (valState.hasCurrentPwd === 'green') ? greenIcon() : redIcon();
      currentPwd = (<div className={valState.hasCurrentPwd}>
                {hasCurrentPwd}
                {Strs.pwdRuleCurrentPwdPresent}</div>
            )
    }
    const minCharIcon = (valState.minChars === 'green') ? greenIcon() : redIcon();
    const letterCheckIcon = (valState.letters === 'green') ? greenIcon() : redIcon();
    const numberCheckIcon = (valState.numbers === 'green') ? greenIcon() : redIcon();
    const confAndPwdMatchIcon  = (valState.pwdMatch === 'green') ? greenIcon() : redIcon();

    // The bottom line of this render block displays illegal characters (which is why it has badChars 
    // as its value).  If there are no bad characters it will be an empty div.  This is why the css for 
    // the block is 'red'. If there are no bad characters nothing shows up in red.
    return (
      <div id="validate-instruct">
      <strong>{Strs.pwdRulesTitle}</strong>
        <div className={bckgrndCss}>
            {currentPwd}
            <div className={valState.letters}>
                {letterCheckIcon}
                {Strs.pwdRuleLetters}</div>
            <div className={valState.numbers}>
                {numberCheckIcon}
                {Strs.pwdRuleNumbers}</div>
            <div className={valState.minChars}>
                {minCharIcon}
                {Strs.pwdRuleMinChars}</div>
            <div className={valState.pwdMatch}>
                {confAndPwdMatchIcon}
                {Strs.pwdRulePwdsMatch}</div>
            <div className="red">{valState.badCharMsg}&nbsp;&nbsp;&nbsp;{valState.badChars}</div>
                {Strs.pwdRuleInvalChars}
        </div>
      </div>
    );
  }

export default PwdValidationCtrl;

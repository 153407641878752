/*******
 * Copyright 2017 - 2025 - EmPowerYu, inc.
 *
 */

import WhiteLabel from '../misc/whiteLabelData';
import { DayOfWeekStrings, OptionsPageString as STRs } from './strings';
let INACTIVITY_TIMEOUT = 15 * 60 * 1000;     // 15 minute for production

export const MIN_PASSWORD_LENGTH = 12;
export const MAX_PASSWORD_LENGTH = 128;

export const passwordChangeTimeout = 10000;

// Get the default true/false defaults for each event and notifications.  These
// values are different based on Monitoring Level
function getDefaultOptionValuesByMLevel( userType ) {
    const customerOptionsDefaults = [
        {
            "normalcyEvents": { "rise": true},
            "outOfPatternEvents": { "lateRise": true, "insufficientActivity": true, },
            "outOfRangeEvents": {"houseTemperature": true},

            "normalcyNotifications": {"email": true, "sms": true},
            "outOfPatternNotifications": {"email": false, "sms": true},
            "outOfRangeNotifications": {"email": false, "sms": true},
        },
        {
            "normalcyEvents": { "rise": true},
            "outOfPatternEvents": { "lateRise": true, "insufficientActivity": false, },
            "outOfRangeEvents": {"houseTemperature": true},

            "normalcyNotifications": {"email": true, "sms": true},
            "outOfPatternNotifications": {"email": false, "sms": true},
            "outOfRangeNotifications": {"email": false, "sms": true},
        },
        {
            "normalcyEvents": { "rise": true},
            "outOfPatternEvents": { "lateRise": true, "insufficientActivity": false, },

            "outOfRangeEvents": {"houseTemperature": true},

            "normalcyNotifications": {"email": true, "sms": true},
            "outOfPatternNotifications": {"email": false, "sms": true},
            "outOfRangeNotifications": {"email": false, "sms": true},
        }
    ];
    const demoOptionDefaults = [
        {
            "normalcyEvents": {"goToBed": false, "meal": false, "rise": true},
            "outOfPatternEvents": {
                "insufficientActivity": false,
                "lateRise": true,
                "missedMeal": false,
                "unusualQuiet": true
            },
            "outOfRangeEvents": {"BP": false, "heartRate": false, "houseTemperature": true, "oximeter": false, "glucose": false},
            "symptomaticEvents": {"nightTimeActivity": false, "repetitiveActions": false, "slowGait": false},

            "normalcyNotifications": {"email": true, "sms": true, "dailyReport": false},
            "outOfPatternNotifications": {"email": false, "sms": true, "dailyReport": false},
            "outOfRangeNotifications": {"email": false, "sms": true, "dailyReport": false},
            "symptomaticNotifications": {"email": false, "sms": false, "dailyReport": false}
        }, {
            "normalcyEvents": {"goToBed": false, "meal": false, "rise": true},
            "outOfPatternEvents": {
                "insufficientActivity": false,
                "lateRise": true,
                "missedMeal": false,
                "unusualQuiet": true
            },
            "outOfRangeEvents": {"BP": true, "heartRate": false, "houseTemperature": true, "oximeter": true, "glucose": false},
            "symptomaticEvents": {"nightTimeActivity": false, "repetitiveActions": false, "slowGait": false},

            "normalcyNotifications": {"email": true, "sms": true, "dailyReport": false},
            "outOfPatternNotifications": {"email": false, "sms": true, "dailyReport": false},
            "outOfRangeNotifications": {"email": false, "sms": true, "dailyReport": false},
            "symptomaticNotifications": {"email": false, "sms": false, "dailyReport": false}
        }, {
            "normalcyEvents": {"goToBed": false, "meal": false, "rise": true},
            "outOfPatternEvents": {
                "insufficientActivity": false,
                "lateRise": true,
                "missedMeal": false,
                "unusualQuiet": true
            },
            "outOfRangeEvents": {"BP": true, "heartRate": false, "houseTemperature": true, "oximeter": true, "glucose": false},
            "symptomaticEvents": {"nightTimeActivity": false, "repetitiveActions": false, "slowGait": false},

            "normalcyNotifications": {"email": true, "sms": true, "dailyReport": false},
            "outOfPatternNotifications": {"email": false, "sms": true, "dailyReport": false},
            "outOfRangeNotifications": {"email": false, "sms": true, "dailyReport": false},
            "symptomaticNotifications": {"email": false, "sms": false, "dailyReport": false}
        }];

    const optionDefaults = Number(userType).toString() === SYSTEM_CONSTANTS.userDemo ? demoOptionDefaults : customerOptionsDefaults;
    return optionDefaults;
}

// Delivery options view data.  This defines the category label for each category of notifications.  It also indicates if 
// the category is visible in each monitoring level and the mapping for the option's attribute name to a friendly name (i.e. label in UI)
function getDeliveryVisibilityAndAttributeMapping( userType ) {
    const customerDeliveryOptionDefaults = [
        {
            label: STRs.normalcyNotifications,
            visible: [true, true, false],
            attrib: "normalcyNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"}
            ]
        }, {
            label: STRs.outOfPatternAlerts,
            visible: [true, true, false],
            attrib: "outOfPatternNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"}
            ]
        }, {
            label: STRs.outOfRangeAlerts,
            visible: [true, true, false],
            attrib: "outOfRangeNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"}
            ]
        },
        {
            label: STRs.symptomaticAlert,
            visible: [false, false, false],
            attrib: "symptomaticNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"},
            ]
        }];

    const demoDeliveryOptionDefaults = [
        {
            label: STRs.normalcyNotifications,
            visible: [true, true, true],
            attrib: "normalcyNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"},
                {label: STRs.dailyReport, attribName: "dailyReport"}
            ]
        }, {
            label: STRs.outOfPatternAlerts,
            visible: [true, true, true],
            attrib: "outOfPatternNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"},
                {label: STRs.dailyReport, attribName: "dailyReport"}
            ]
        }, {
            label: STRs.outOfRangeAlerts,
            visible: [true, true, true],
            attrib: "outOfRangeNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"},
                {label: STRs.dailyReport, attribName: "dailyReport"}
            ]
        },
        {
            label: STRs.symptomaticAlerts,
            visible: [false, false, true],
            attrib: "symptomaticNotifications",
            items: [
                {label: STRs.email, attribName: "email"},
                {label: STRs.sms, attribName: "sms"},
                {label: STRs.dailyReport, attribName: "dailyReport"}
            ]
        }];

    const deliveryOptionDefaults = (Number(userType).toString() === SYSTEM_CONSTANTS.userDemo) ? demoDeliveryOptionDefaults : customerDeliveryOptionDefaults;

    return deliveryOptionDefaults;
}

// Event notification option view data.  This defines the category label for each grouping of events.  It also indicates if the category
// is visible in a monitoring level and the mapping for the option's attribute name to a friendly name (i.e. label in UI)
    function getEventVisibilityAndAttributeMapping(userType) {
    const customerEventOptionDefaults = [
        {
            label: STRs.normalcyNotifications,
            visible: [true, true, true],
            attrib: "normalcyEvents",
            items: [
                {label: STRs.morningRiseTime, attribName: "rise", advSetting: false },
            ]
        }, {
            label: STRs.outOfPatternAlerts,
            visible: [true, true, true],
            attrib: "outOfPatternEvents",
            items: [
                {label: STRs.unusuallyLateRise, attribName: "lateRise", advSetting: false },
                {label: STRs.unusualInactivity, attribName: "unusualQuiet"},  // uncomment when Kirk finishes this
            ]
        }, {
            label: STRs.outOfRangeAlerts,
            visible: [true, true, true],
            attrib: "outOfRangeEvents",
            items: [
                {label: STRs.houseTemperature, attribName: "houseTemperature"},
            ]
        },
        {
            label: STRs.symptomaticAlerts,
            visible: [false, false, false],
            attrib: "symptomaticEvents",
            items: [
                {label: STRs.increasedNighttimeActivity, attribName: "nightTimeActivity"},
                {label: STRs.unusuallySlowWalkingSpeed, attribName: "slowGait"},
                {label: STRs.repetitiveActivity, attribName: "repetitiveActions"}
            ]
        }];

    const demoEventOptionDefaults = [
        {
            label: STRs.normalcyNotifications,
            visible: [true, true, true],            // Show category based on mlevel
            attrib: "normalcyEvents",
            items: [
                {label: STRs.morningRiseTime, attribName: "rise", advSetting: false },
                {label: STRs.firstDailyMeal, attribName: "meal", advSetting: false },
                {label: STRs.eveningBedtime, attribName: "goToBed", advSetting: false }
            ]
        }, {
            label: STRs.outOfPatternAlerts,
            visible: [true, true, true],    // Show category based on mlevel
            attrib: "outOfPatternEvents",
            items: [
                {label: STRs.unusuallyLateRise, attribName: "lateRise"},
                {label: STRs.unusualInactivity, attribName: "unusualQuiet"},
                {label: STRs.unusuallyLowActivity, attribName: "insufficientActivity"},
                {label: STRs.unusuallyLateFirstMeal, attribName: "missedMeal"}
            ]
        }, {
            label: STRs.outOfRangeAlerts,
            visible: [true, true, true],        // Show category based on mlevel
            attrib: "outOfRangeEvents",
            items: [
                {label: STRs.houseTemperature, attribName: "houseTemperature"},
                {label: STRs.bloodPressure, attribName: "BP", advSetting: false},  // need to change code to handle 2 ranges.
                {label: STRs.bloodOxygenSaturation, attribName: "oximeter"},
                {label: STRs.heartRate, attribName: "heartRate"},
                {label: STRs.glucoseLevels, attribName: "glucose"}
            ]
        },
        {
            label: STRs.symptomaticAlerts,        // Show category based on mlevel
            visible: [false, false, true],
            attrib: "symptomaticEvents",
            items: [
                {label: STRs.increasedNighttimeActivity, attribName: "nightTimeActivity"},
                {label: STRs.unusuallySlowWalkingSpeed, attribName: "slowGait"},
                {label: STRs.repetitiveActivity, attribName: "repetitiveActions"}
            ]
        }];

    const eventOptionDefaults = Number(userType).toString() === SYSTEM_CONSTANTS.userDemo ? demoEventOptionDefaults : customerEventOptionDefaults;

    return eventOptionDefaults;
}

// validation techiniques:
//   "P" - percentage - valid range specified
//   "T" - time
//   "TP" - time period (e.g., number of hours)
//   "EMV" - each value is a max value (e.g., blood pressure)
//   "VTR" - valid range of numbers (outside of range is invalid)
function getAdvOptValidationDefaults() {
    const advOptValidationDefaults = {
        insufficientActivity:   { validation: "P", min: 10, max: 150, inc: 10},
        oximeter:               { validation: "P", min: 90, max: 100, inc: 1},
        slowGait:               { validation: "P", min: 10, max: 150, inc: 10},
        nightTimeActivity:      { validation: "P", min: 0, max: 150, inc: 10},
        lateRise:               { validation: "T", min: 0, max: 0, inc: 0},
        missedMeal:             { validation: "T", min: 0, max: 0, inc: 10},
        // UnusualQuiet 'inc' should equal '.5'.  Unfortunately the data base can't handle 
        // floating point number (it is a smallint).  So for now we will use increments of 1.
        unusualQuiet:           { validation: "TP", min:4, max: 12, inc: 1 },  // inc: .5 
        BP:                     { validation: "EMV", min: 60, max: 160, inc: 5 },
        houseTemperature:       { validation: "VTR", min: 55, max: 85, inc: 1 },
        heartRate:              { validation: "VTR", min: 50, max: 100, inc: 1 },
        glucose:                {  validation: "VTR", min: 70, max: 130, inc: 5 }
    };

    return advOptValidationDefaults;
}

/*
    The following options still need content:
    - blood oxygen saturation       - collecting data
    - heart rate                    - collecting data
    - unusually late first meal     - data collected - need Kirk - analytics need to be written tested & validated
    - insufficient activity         - data collected - need Kirk - analytics need to be written tested & validated
    - increased nighttime activity  - data collected - need Kirk - analytics need to be written tested & validated
    - Slow gait                     - data collected - need Kirk - analytics need to be written tested & validated
    - glucose                       - data collected - need Kirk - analytics need to be written tested & validated
    - repetitive activity           - no data yet - in Laura's imagination
 */
export function getAdvancedOptionsStrings(label) {
    const whiteLabel = label !== undefined ? label : 'empoweryu';

    const wlData = WhiteLabel.getWhiteLabelData(whiteLabel);
    const name = wlData.name;

    const advancedOptionStrings = {
        insufficientActivity: {
            title: STRs.insufficientActivityTitle,
            descriptions: [ STRs.insufficientActivityDescription ],
            label1: STRs.insufficientActivityLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlUseEMPYuValue(name)
        },
        oximeter: {
            title: STRs.oximeterTitle,
            descriptions: [
                STRs.wlOximeterDescription1(name),
                STRs.oximeterDescription2
            ],
            label1: STRs.oximeterLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlOximeterEMPYuOption(name)
        },
        heartRate: {
            title: STRs.heartRateTitle,
            descriptions: [
                STRs.wlHeartRateDescription1(name),
                STRs.heartRateDescription2
            ],
            label1: STRs.heartRateLabel1,
            label2: STRs.heartRateLabel2,
            mpyuOption: STRs.wlHeartRateEMPYuOption(name)
        },
        slowGait: {
            title: STRs.slowGaitTitle,
            descriptions: [ STRs.slowGaitDescriptions ],
            label1: STRs.slowGaitLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlUseEMPYuValue(name)
        },
        nightTimeActivity: {
            title: STRs.nightTimeActivityTitle,
            descriptions: [ STRs.nightTimeActivityDescriptions ],
            label1: STRs.nightTimeActivityLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlUseEMPYuValue(name)
        },
        lateRise: {
            title: STRs.lateRiseTitle,
            descriptions: [
                STRs.wlLateRiseDescriptions1(name),
                STRs.lateRiseDescriptions2
            ],
            label1: STRs.lateRiseLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlLateRiseEMPYuOption(name)
        },
        unusualQuiet: {
            title: STRs.unusualQuietTitle,
            descriptions: [
                STRs.wlUnusualQuietDescriptions1(name),
                STRs.unusualQuietDescriptions2
            ],
            label1: STRs.unusualQuietLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.unusualQuietEMPYuOption
        },
        BP: {
            title: STRs.bpTitle,
            descriptions: [
                STRs.wlBpDescriptions1(name),
                STRs.bpDescriptions2
            ],
            label1:STRs.bpLabel1,
            label2: STRs.bpLabel2,
            mpyuOption: STRs.wlBpEMPYuOption(name)
        },
        houseTemperature: {
            title: STRs.houseTempTitle,
            descriptions: [ STRs.wlHouseTempDescriptions(name) ],
            label1: STRs.houseTempLabel1,
            label2: STRs.houseTempLabel2,
            mpyuOption: STRs.houseTempEMPYuOption
        },
        missedMeal: {
            title: STRs.missedMealTitle,
            descriptions: [ STRs.missedMealDescriptions ],
            label1: STRs.missedMealLabel1,
            label2: STRs.unsusedLabel,
            mpyuOption: STRs.wlUseEMPYuValue(name)
        },
        glucose: {
            title: STRs.glucoseTitle,
            descriptions: [
                STRs.wlGlucoseDescriptions1(name),
                STRs.glucoseDescriptions2
            ],
            label1: STRs.glucoseLabel1,
            label2: STRs.glucoseLabel2,
            mpyuOption: STRs.glucoseEMPYuOption
        }
    };

    return advancedOptionStrings;
}

function getSystemConstants() {
    return {
        userPatient: "0",
        userFamily: "1",
        userCaregiver: "2",
        userAdmin: "3",
        userDemo: "4",
    }
}

const CONNECTIONSTATUS_ENUM = {
    disconnected: "0",
    connected: "1",
    sensorIssue: "2",
};

const CONNECTIONSTATUSICON = [
			"imgSystemDisconnected",
            "imgSystemConnected",
            "imgSensorIssues"
        ];
const BEHAVIORSTATE_ENUM = {
    unknown: 0,
    sleeping: 1,
    active: 2,
    noMovement: 3,
    away: 4
};

const BEHAVIORSTATEICON = [
            "imgStatusUnknown",
            "imgSleeping",
            "imgMovement",
            "imgNoMovement",
            "imgAway"
        ];

const BEHAVIORSTATUSICON = [
            "imgBehaviorStatusUnknown",
            "imgBehaviorStatusLow",
            "imgBehaviorStatusMedium",
            "imgBehaviorStatusHigh"
        ];

const PHYSIOLOGYSTATUSICON = [
            "imgPhysioStatusUnknown",
            "imgPhysioStatusLow",
            "imgPhysioStatusMedium",
            "imgPhysioStatusHigh"
        ];

const ENVIRONMENTALSTATUSICON = [
            "imgEnvStatusUnknown",
            "imgEnvStatusLow",
            "imgEnvStatusMedium",
            "imgEnvStatusHigh"
        ];

const CONCERNSTATUSICONS = [
            "imgConcernUnknown",
            "imgConcernLow",
            "imgConcernMedium",
            "imgConcernHigh",
];

// Put these in iso order (Monday, Tuesday...)
const DAYOFWEEKMAP = [
    DayOfWeekStrings.monday,
    DayOfWeekStrings.tuesday,
    DayOfWeekStrings.wednesday,
    DayOfWeekStrings.thursday,
    DayOfWeekStrings.friday,
    DayOfWeekStrings.saturday,
    DayOfWeekStrings.sunday
];

const SYSTEM_CONSTANTS = getSystemConstants();
let EVENT_VISIBILITY_AND_ATTRIB_MAPPING = getEventVisibilityAndAttributeMapping(SYSTEM_CONSTANTS.userPatient);
let DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING = getDeliveryVisibilityAndAttributeMapping(SYSTEM_CONSTANTS.userPatient);
let DEFAULT_OPTION_VALUES_BY_MLEVEL = getDefaultOptionValuesByMLevel(SYSTEM_CONSTANTS.userPatient);

const ADVANCED_OPTION_VALIDATION_DEFAULTS = getAdvOptValidationDefaults();
const ADVANCED_OPTION_STRINGS = getAdvancedOptionsStrings();

function setUserOptionDefaults(userType) {
    EVENT_VISIBILITY_AND_ATTRIB_MAPPING = getEventVisibilityAndAttributeMapping(userType);
    DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING = getDeliveryVisibilityAndAttributeMapping(userType);
    DEFAULT_OPTION_VALUES_BY_MLEVEL = getDefaultOptionValuesByMLevel(userType);

    // console.log(`setUserOptionDefaults: userType = ${userType} 
    // EVENT_VISIBILITY_AND_ATTRIB_MAPPING: `, EVENT_VISIBILITY_AND_ATTRIB_MAPPING, `
    // DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING`, DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING, `
    // DEFAULT_OPTION_VALUES_BY_MLEVEL`, DEFAULT_OPTION_VALUES_BY_MLEVEL
    // )
}

function setInactivityTimeout(timeout) {
    // INACTIVITY_TIMEOUT = 60 * 1000;
    INACTIVITY_TIMEOUT = timeout * 1000;
}
export {
    DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING,
    EVENT_VISIBILITY_AND_ATTRIB_MAPPING,
    DEFAULT_OPTION_VALUES_BY_MLEVEL,
    ADVANCED_OPTION_VALIDATION_DEFAULTS,
    ADVANCED_OPTION_STRINGS,
    CONNECTIONSTATUSICON,
	BEHAVIORSTATEICON,
	BEHAVIORSTATUSICON,
	PHYSIOLOGYSTATUSICON,
	ENVIRONMENTALSTATUSICON,
    CONCERNSTATUSICONS,
    SYSTEM_CONSTANTS,
    DAYOFWEEKMAP,
    CONNECTIONSTATUS_ENUM,
    BEHAVIORSTATE_ENUM,
    setUserOptionDefaults,
    setInactivityTimeout,
    INACTIVITY_TIMEOUT
};

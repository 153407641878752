/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

const _sessionStorageAPIs = function () {
    return {
        setWhiteLabelString: function (wlName) {
            sessionStorage.setItem('wlName', wlName);
        },

        setWhiteLabelFavIcon: function(favIcon) {
            sessionStorage.setItem('wlFIco', favIcon);
        },


        setWhitePName: function(wlPName) {
            sessionStorage.setItem('wlPName', wlPName);
        },

        getWhiteLabelString: function() {
            return sessionStorage.getItem('wlName');
        },

        setCaregiverId: function setCaregiverId(userName, organizationName, caregiverId, userType, inactivityTimeout) {
            sessionStorage.setItem('cgId', caregiverId);
            sessionStorage.setItem('userType', userType);
            sessionStorage.setItem('userName', userName);
            sessionStorage.setItem('organizationName', organizationName);
            sessionStorage.setItem('inactivityTimeout', inactivityTimeout);
        },

        getInactivityTimeout: function() {
            return sessionStorage.getItem("inactivityTimeout");
        },

        updateCaregiverId: function updateCaregiverId(caregiverId) {
            sessionStorage.setItem('cgId', caregiverId);
        },

        getCaregiverId: function getCaregiverId() {
            return sessionStorage.getItem("cgId");
        },

        setUserType: function setUserTyped(userType) {
            sessionStorage.setItem('userType', userType);
        },

        getUserType: function getUserType() {
            return sessionStorage.getItem("userType");
        },

        getUsername: function getUsername() {
            return sessionStorage.getItem("userName");
        },

        getOrganizationName: function getOrganizationName() {
            return sessionStorage.getItem("organizationName");
        },

        setPatientList: function setPatientList(pList, pObject) {
            let lTime = Date.now();
            sessionStorage.setItem('pList', JSON.stringify(pList));
            sessionStorage.setItem('pObject', JSON.stringify(pObject));
            sessionStorage.setItem('pListUpdateTime', lTime.toString())
        },

        getPatientList: function getPatientList() {
            return JSON.parse(sessionStorage.getItem("pList"));
        },

        getPatientObject: function getPatientObj() {
            return JSON.parse(sessionStorage.getItem("pObject"));
        },

        setPatientId: function (id) {
            if (typeof id === 'string') {
                sessionStorage.setItem('currentPID', JSON.stringify(id));
            }
        },

        getPatientId: function() {
            return sessionStorage.getItem('currentPID');
        },

        getPatientVUrl: function() {
            return sessionStorage.getItem('vUrl');
        },

        setPatientVUrl: function(vUrl) {
            sessionStorage.setItem('vUrl', vUrl);
        },

        removePatientVUrl: function() {
            sessionStorage.removeItem('vUrl');
        },

        resetSessionStorage: function resetSessionStorage() {
            sessionStorage.removeItem("cgId");
            sessionStorage.removeItem("pList");
            sessionStorage.removeItem("pListUpdateTime");
            sessionStorage.removeItem("userType");
            sessionStorage.removeItem('currentPID');
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('pObject');
            sessionStorage.removeItem('inactivityTimeout');
            sessionStorage.removeItem('vUrl');
            sessionStorage.removeItem('wlName');
            sessionStorage.removeItem('wlFIco')
            sessionStorage.removeItem('wlPName')
        },
    }
};

const LocaleStorageAPI = _sessionStorageAPIs();

export default LocaleStorageAPI;

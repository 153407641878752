/* eslint-disable no-undef */
/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */

import React from 'react';
import CaregiverServices from "../DataService/CareGiverService";
import {URLS} from './urls';
// import PatientDataStorage from "../DataService/DataService";

class _makeUtilities {
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    calculateLastActivity(lastActivity) {
        let text = "";

        try {
            let hours = Math.floor(lastActivity / 3600); // seconds*minutes
            let min = Math.floor((lastActivity % 3600) / 60);
            let seconds = lastActivity % 60;

            if (lastActivity > 0) {
                if (hours >= 4) {
                    text = <strong>over 4 hours</strong>;
                } else if (lastActivity >= 60) {
                    text = <strong>{hours}h {min}m</strong>
                } else {
                    text = <strong>{seconds} secs</strong>
                }
            } else {
                text = "";
            }
        }
        catch(e) {
            console.log('calculateLastActivity: '+e);
        }
        return text
    }

    timeDataToString(timeData) {
        let text = "";
        try {
            let hours = timeData.hours, min = timeData.min;

            if (hours === "" || min === "") {
                hours = min = "--"
            } else {
                if (timeData.ampm === "pm") {
                    hours += 12;
                }
                if (hours < 10) {
                    if (hours === 0) {
                        hours = "12";
                    } else {
                        hours = "0" + hours;
                    }
                }
                if (min < 10) {
                    min = "0" + min;
                }
            }
            text = hours + ":" + min;
        }
        catch(e) {
            console.log('timeDataToString: ' + e);
        }

        return text;
    }

    timeDataToDisplayString(timeData) {
        let hours = timeData.hours, min = timeData.min;
        try {
            if (hours === "" || min === "") {
                hours = min = "--"
            } else {
                if (hours < 10) {
                    if (hours === 0) {
                        hours = "12";
                    } else {
                        hours = "0" + hours;
                    }
                }
                if (min < 10) {
                    min = "0" + min;
                }
            }
        }
        catch(e) {
            console.log('timeDataToDisplayString: ' + e);
        }
        return hours + ":" + min + " " + timeData.ampm;
    }

    timeDataToDisplayDateTime(timeData, fExcludeMidnight) {
        let str = "";
        try {
            str = this.timeDataToDisplayString(timeData);
            if (fExcludeMidnight && str.indexOf("00:00") !== -1) {
                str = "";
            }
            if (timeData.datestring && timeData.datestring.length > 0) {
                str = timeData.datestring + " " + str;
            }
        }
        catch(e) {
            console.log('timeDataToDisplayDateTime: ' + e);
        }
        return str;
    }

    strTimeToTimeData(time) {
        let hours = "", min = "", ampm = "";
        try {
            if (typeof(time) === "string") {
                let values = time.split(":");
                hours = Number.parseInt(values[0], 10);
                min = Number.parseInt(values[1], 10);
                ampm = "am";
                if (hours >= 12) {
                    hours -= 12;
                    ampm = "pm";
                }
            } else {
                console.log('strTimeToTimeData: Passed invalid time. (' + time.toString() + ')');
                hours = min = 0;
                ampm = 'am';
            }
        }
        catch(e) {
            console.log("strTimeToTimeData: " + e);
        }
        return {ts: undefined, date: undefined, hours: hours, min: min, ampm: ampm};
    }
/*
    convertTStampToTimeData(ts, fAlwaysIncludeDate) {
        let hours, min;
        let dtime = {};

        if (!ts) {
            dtime = {date: undefined, hours: "", min: "", ampm: ""};
            console.log("convertTStampToTimeData:  undefined timestamp");
        } else {
            try {
                let date = new Date();
                let today = new Date();
                let dstr = "";

                if (ts !== -1) {
                    date.setTime(ts);

                    hours = date.getHours();
                    min = date.getMinutes();
                    dtime = this.strTimeToTimeData(hours + ":" + min);

                    if (fAlwaysIncludeDate ||
                        (today.getMonth() !== date.getMonth() ||
                            today.getDate() !== date.getDate() ||
                            today.getFullYear() !== date.getFullYear())) {
                        let mon = date.getMonth() + 1;
                        mon = ((mon < 10) ? "0" : "" ) + parseInt(mon, 10);

                        let day = date.getDate();
                        day = ((day < 10) ? "0" : "" ) + parseInt(day, 10);
                        dstr = mon + "/" + day + "/" + date.getFullYear();
                    }
                    dtime.date = dstr;
                } else {
                    hours = "--";
                    min = "--";
                    dtime = {date: undefined, hours: hours, min: min, ampm: ""};
                }
                dtime.ts = ts;
            }
            catch (e) {
                console.log('convertTStampToTimeData: ' + e);
            }
        }
        return dtime;
    }
*/
    convertMomentToTimeData(momentDate, fAlwaysIncludeDate) {
        let hours, min;
        let dtime = {};

        if (!momentDate) {
            dtime = {datestring: undefined, hours: "", min: "", ampm: ""};
            console.log("convertMomentToTimeData:  undefined momentDate");
        } else {
            try {
                // eslint-disable-next-line no-undef
                var moment = require('moment-timezone');
                let timezoneName = momentDate.tz().timezoneName;
                let today = new moment.tz(timezoneName);
                
                let dstr = "";

                if (momentDate !== -1) {

                    hours = momentDate.hours();
                    min = momentDate.minutes();
                    dtime = this.strTimeToTimeData(hours + ":" + min);

                    if (fAlwaysIncludeDate ||
                        (today.month() !== momentDate.month() ||
                            today.date() !== momentDate.date() ||
                            today.year() !== momentDate.year())) {
                        let mon = momentDate.month() + 1;
                        mon = ((mon < 10) ? "0" : "" ) + parseInt(mon, 10);

                        let day = momentDate.date();
                        day = ((day < 10) ? "0" : "" ) + parseInt(day, 10);
                        dstr = mon + "/" + day + "/" + momentDate.year();
                    }
                    dtime.datestring = dstr;
                } else {
                    hours = "--";
                    min = "--";
                    dtime = {datestring: undefined, hours: hours, min: min, ampm: ""};
                }
                dtime.ts = momentDate;
            }
            catch (e) {
                console.log('convertMomentToTimeData: ' + e);
            }
        }
        return dtime;
    }

    convertISO8601wTZToTimeData(iso8601Array, fAlwaysIncludeDate) {
        let hours, min;
        let dtime = {};

        if (!iso8601Array) {
            dtime = {datestring: undefined, hours: "", min: "", ampm: ""};
            // console.log("convertISO8601wTZToTimeData:  undefined isoiso8601Array8601String");
        } else {
            try {
                var moment = require('moment-timezone');
                
                let dstr = "";

                // console.log(`convertISO8601wTZToTimeData ${iso8601Array.tz} ${iso8601Array.datetime}`)
                if (iso8601Array !== null && iso8601Array.datetime !== "--:--" && iso8601Array.datetime[0] !== '-') {
                    let today = new moment.tz(iso8601Array.tz);
                    let date = new moment.tz(iso8601Array.datetime, iso8601Array.tz);

                    hours = date.hours();
                    min = date.minutes();
                    dtime = this.strTimeToTimeData(hours + ":" + min);

                    if (fAlwaysIncludeDate ||
                        (today.month() !== date.month() ||
                            today.date() !== date.date() ||
                            today.year() !== date.year())) {
                        let mon = date.month() + 1;
                        mon = ((mon < 10) ? "0" : "" ) + parseInt(mon, 10);

                        let day = date.date();
                        day = ((day < 10) ? "0" : "" ) + parseInt(day, 10);
                        dstr = mon + "/" + day + "/" + date.year();
                    }
                    dtime.datestring = dstr;
                } else {
                    hours = "--";
                    min = "--";
                    dtime = {datestring: undefined, hours: hours, min: min, ampm: ""};
                }
                dtime.ts = iso8601Array;
            }
            catch (e) {
                console.log('convertISO8601wTZToTimeData: ' + e);
            }
        }
        return dtime;
    }

    goToPage(history, url, data) {
        let location;
        if (history) {
            if (typeof data !== "object") {
                alert('goToPage was passed a data argument which is not an object.');
            }
            location = {
                pathname: url,
                state: data
            };
            history.push(location);
        } else {
            location = {
                pathname: '/',
            };
            history.push(location);
        }
    }

    goHome(history, caregiverId) {
        let location;
        if (history && caregiverId) {
            location = {
                pathname: URLS.goHome,
                state: { caregiverId: caregiverId }
            };
            history.push(location);
        } else {
            location = {
                pathname: URLS.login,
                state: { caregiverId: caregiverId }
            };
            history.push(location);
        }
    }

    cleanupGoToLogin(history, showAlert) {
        if (showAlert) {
            // PatientDataStorage.setDisplayTimeoutPopped(true);
            setTimeout(() => {
                alert('you have been logged out for inactivity.')
            }, 30);
        }

        setTimeout(()=> {
            window.location.replace(URLS.logout)
        }, 1);
        CaregiverServices.forceLogout();
    }
}

let Utils = new _makeUtilities();

export default Utils;

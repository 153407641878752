/*******
 * Copyright 2017 - 2024 - EmPowerYu, inc.
 *
 */
import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import mpylogo from  '../images/EPY-Logo.svg';
import aiCaringLogo from  '../images/AI-Caring-Logo.svg';
import LocalStorageAPI from "../DataService/LStorage";

// To make the code base white label friendly I created 3 things: 
//
//     - a whiteLabelList name array - This array contains a string (the white label) for each 
//       company that we provide white label support. 
// 
//     - a whiteLabelData object - This object contains the data used for all labels, icons, and FavIcon 
//       for each white label.  There is also an entry for EmpowerYu. Usin the current whiteLabelData entry, 
//       all the code can look at a single place for the correct data to use for labels, icons, and favicons.  
//     
//      - a whiteLabel helper object - this object will get you the current white list data

// As we add new white label brands we will add new label types to this array.  
// The value must be included in the domain or we will default to 'empoweryu' stuff.
// To get information needed for this white label use whiteLabelData.
export const WhiteLabelList = [
    "ai-caring",
]

const WhiteLabelData = {
    "ai-caring": {
        name: 'AI Caring',
        logo:  <Navbar.Brand href="#home"><img src={aiCaringLogo} alt="AI Caring logo" className={"d-inline-block align-top"}/></Navbar.Brand>,
        favIcon: '/static/aicaring-favicon.ico'
    },
    empoweryu: {
        name: 'EmPowerYu',
        logo:  <Navbar.Brand href="#home"><img src={mpylogo} alt="EmPowerYu logo" width="87" className={"d-inline-block align-top"}/></Navbar.Brand>,
        favIcon: '/static/epyu-favicon.ico'
    },
}

class WhiteLabelObj {
    get() {
        const label = LocalStorageAPI.getWhiteLabelString();
        let whiteLabelStuff = undefined;
        
        if (label === undefined) {
            alert('White Label Data has not be saved yet!')
        } else {
            whiteLabelStuff = WhiteLabelData[label];
        }
        return whiteLabelStuff;
    }

    getWhiteLabelString() {
        const wlName = LocalStorageAPI.getWhiteLabelString();
        if (wlName === undefined) {
            alert('No White Label defined!')
        }
        return wlName; 
    }

    getWhiteLabelData(name) {
        let wld = WhiteLabelData[name];
        if (wld === undefined) {
            alert(`No white label data for name ${name}`)
        } 
        return wld;
    }

    
    // reset current white label based on the current URL
    updateWhiteLabel() {
        let rc = false;
        let label = LocalStorageAPI.getWhiteLabelString();
        if (!label) {
            rc = true;
            label = "empoweryu";

            const location = window.location.href;
            
            for (let i=0, len=WhiteLabelList.length; i < len; i++) {
                const wl = WhiteLabelList[i];
                if (location.indexOf(wl) !== -1) {
                    label = wl;
                    break;
                }
            }
            LocalStorageAPI.setWhiteLabelString(label);
            LocalStorageAPI.setWhiteLabelFavIcon(WhiteLabelData[label].favIcon);
            LocalStorageAPI.setWhitePName(WhiteLabelData[label].name);
        }

        return rc;
    }
}

const WhiteLabel = new WhiteLabelObj
export default WhiteLabel;

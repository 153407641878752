/*******
 * Copyright 2017-2025 - EmPowerYu, inc.
 *
 */
import React, { useState } from 'react';
import Button from "react-bootstrap/Button";

import { PropTypes } from 'prop-types';
import { AccountStrings as Strs } from './strings';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from './constants';

import logger from './logUtilities';
import PwdValidationCtrl from './PwdValidation';

// export PwdValidationState {
//     badCharMsg: string;
//     badChars: string;
//     letters: string;
//     minChars: string;
//     numbers: string;
//     pwdMatch: string;
//     validPwd: boolean;
// }

export const resetPwdData = ( pwdValState ) => {
    pwdValState.password =  '';
    pwdValState.newPassword =  '';
    pwdValState.confirmPassword =  '';
    
    pwdValState.pwdValidation = {
        badCharMsg: '',
        badChars: '',
        letters: 'red',
        minChars: 'red',
        numbers: 'red',
        pwdMatch: 'red',
        validPwd: false
    }
}

// r - required,
// n - numbers,
// a - a-z/A-Z/0-9; also '.' and '-'
// l - a-z/A-Z; also '.' and '-'
// e - email
// p - phone
export const validateEntry = (s, valType) => {
    const regNumbers = /^[0-9\s]*$/;
    const regLetterNumbers = /^[a-zA-Z0-9\s.-]*$/;
    const regLetters = /^[a-zA-Z\s.-]*$/;
    const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    // make sure the field is not undefined and is a string.  Also trim it.
    s = (s) ? s : '';
    s = s.trim();

    if (valType.indexOf('r') !== -1) {      // check to make sure required field not empty
        if (!s.length) {
            return {isValid: false, s: ''};
        }
    }
    if (valType.indexOf('n') !== -1) {      // validate numbers
        if (!regNumbers.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('l') !== -1) {      // Validate letters (and '.')
        if (!regLetters.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('a') !== -1) {      // Validate alphanumerics (a-z, A-Z, 0-9, '.' and '-'
        if (!regLetterNumbers.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('e') !== -1) {      // validate email
        if (!regEmail.test(s)) {
            return {isValid: false, s};
        }
    }

    return {isValid: true, s};
}
    
const pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$/;
const pwdRegex1 = /^[A-Za-z0-9$@$!%*#?&]$/;
const regn = /^(?=.*[0-9])/;
const rega = /^(?=.*[a-zA-z])/;
// const regsc = /^(?=.*[d$@$!%*#?&])/;

const  getIllegalPwdChars = (s) => {
    let badChars = '';
    for (let i = 0, len = s.length; i < len; i++) {
        const c = s.charAt(i);
        if (!pwdRegex1.test(c)) {
            badChars += c;
        }
    }

    return badChars;
}

// pass a dummy string for currentPwd (such as 'not-needed') if creating a new user or reseting a password
export function validatePwd(currentPwd, newPwd, confirmPwd) {
    let validPassword = true;
    const results = {
        pwdMatch: 'red',
        numbers: 'red',
        letters: 'red',
        minChars: 'red',
        hasCurrentPwd: 'red',
        validPwd: false,
        badCharMsg: '',
        badChars: '',
    };

    if (!currentPwd || currentPwd.length === 0) {
        validPassword = false;
    } else {
        results.hasCurrentPwd ='green';
    }

    if (newPwd) {    // Make sure we have a good new password
        results.minChars = (newPwd.length >= MIN_PASSWORD_LENGTH) ? 'green' : 'red';

        if (!pwdRegex.test(newPwd)) {    // check if it passes validity rules
            results.numbers = (regn.test(newPwd)) ? 'green' : 'red';
            results.letters = (rega.test(newPwd)) ? 'green' : 'red';

            // check for illegal chars in the password
            const badChars = getIllegalPwdChars(newPwd);
            if (badChars.length > 0) {
                validPassword = false;
                results.badCharMsg = Strs.badCharacters;
                results.badChars = badChars;
            }
        } else {    // password is valid against rules
            results.numbers = results.letters = 'green';
        }

        if (confirmPwd) {
            if ((newPwd.length >= MIN_PASSWORD_LENGTH && confirmPwd.length >= 0) &&
                (newPwd === confirmPwd)) {
                results.pwdMatch = 'green'
            } else {
                validPassword = false;
            }
        } else {
            validPassword = false;
        }
    } else {
        validPassword = false;
    }

    results.validPwd = validPassword;
    return results;
}

export const PasswordControl = (props) => {
    const {currentPassword, promptForCurrentPassword, validatationBkgCss, callback} = props;
    const [currentPwd, setCurrentPwd] = useState(currentPassword);
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [validationState, setValidationState] = useState({
        pwdMatch: 'red',
        numbers: 'red',
        letters: 'red',
        minChars: 'red',
        hasCurrentPwd: 'red',
        validPwd: false,
        badCharMsg: '',
        badChars: '',
    });
    
    const handleConfirmPwdUpdate = (e) => {
        const pwd = e.currentTarget.value;
        setConfirmPwd(pwd);
        const vState = validatePwd(currentPwd, newPwd, pwd);
        setValidationState(vState);
    }

    const handleNewPwdUpdate = (e) => {
        const pwd = e.currentTarget.value;
        setNewPwd(pwd);
        const vState = validatePwd(currentPwd, pwd, confirmPwd)
        setValidationState(vState);
    }
    
    const handleCurrentPwdUpdate = (e) => {
        if (!props.promptForCurrentPassword) {
            logger.alert('handleCurrentPwdUpdate: Got called even though we were told we do not care about old password');
        } else {
            const pwd = e.currentTarget.value;
            setCurrentPwd(pwd);
            const vState = validatePwd(pwd, newPwd, confirmPwd)
            setValidationState(vState);
        }
    }

    // const {currentPassword, promptForCurrentPassword, newPassword, confirmPassword, validatationBkgCss} = props;
    const valBkg = props.validatationBkgCss && validatationBkgCss.length > 0 ? validatationBkgCss : undefined;
    const currentPwdMarkup = (promptForCurrentPassword === true) ? (
        <>
        {Strs.currentPassword}
        <div className="input-group input-group-lg padbottom-10">
            <input id="currentPassword" type="password" className="form-control" 
                placeholder={Strs.currentPassword} onChange={handleCurrentPwdUpdate} 
                value={currentPwd} maxLength={MAX_PASSWORD_LENGTH} required />
            <span className="input-group-addon"></span>
        </div>
        </>
    ) : (<div></div>)

    const buttonCss = validationState.validPwd ? 'enabled-cursor ' : 'disabled-cursor';
    return (
        <>
        <div className="row validate reset-margins">
            <div className="col-md-6">
                <div className="row">
                    {currentPwdMarkup}
                    {Strs.newPassword}
                    <div className="input-group input-group-lg padbottom-10">
                        <input id="newPassword" type="password" className="form-control" 
                            placeholder={Strs.newPassword} onChange={handleNewPwdUpdate} 
                            value={newPwd} maxLength={MAX_PASSWORD_LENGTH} required />
                        <span className="input-group-addon">&nbsp; </span>
                    </div>

                    {Strs.confirmPassword}
                    <div className="input-group input-group-lg pwd-ctrl padbottom-10">
                        <span className="fas fa-lock"></span>
                        <input id="confirmPassword" type="password" className="form-control" 
                                placeholder={Strs.confirmPassword} value={confirmPwd} 
                                onChange={handleConfirmPwdUpdate} maxLength={MAX_PASSWORD_LENGTH} required/>
                        
                                <span className="input-group-addon">
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-md-offset-2 col-md-6">
                <PwdValidationCtrl valState={validationState} promptForCurrentPassword={promptForCurrentPassword}
                                    background={valBkg}/>
            </div>
            <div>
                <Button disabled={!validationState.validPwd} className={buttonCss}
                        onClick={() => {
                            (callback)({newPwd, currentPwd: currentPwd, valid: validationState.validPwd, confirmPwd})}}>
                        {Strs.changePassword}
                </Button>
            </div>
        </div>
        </>
    )
}
 
PasswordControl.propTypes = {
    currentPassword: PropTypes.string,
    promptForCurrentPassword: PropTypes.bool,
    validatationBkgCss: PropTypes.string,
    callback: PropTypes.func,  
}


export default PasswordControl;

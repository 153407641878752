/*******
 * Copyright 2017-2024 - EmPowerYu, inc.
 *
 */

import { URLS } from '../shared/urls';
import LocalStorageAPI from './LStorage';
import {setUserOptionDefaults, setInactivityTimeout } from '../shared/constants';
import axios from 'axios';
import WhiteLabel, { WhiteLabelList } from '../misc/whiteLabelData';

let epyCaregiverId = undefined;
let epyUserType = undefined;
let epyOrganizationName = undefined;
let epyloginListeners = {};

function EpyCaregiverServices() {
    function validateUserCredentials(username, password, handler) {
        let url = URLS.validateusercredentials;

        axios.post(url, { username: username, password: password})
            .then(function (response) {
                let jsonData = response.data;
                let userInfo;

                try {
                    if (typeof jsonData === "string") {
                        userInfo = JSON.parse(jsonData);
                    } else {
                        userInfo = jsonData;
                    }

                    if (userInfo.success) {
                        epyCaregiverId = userInfo.caregiverId;
                        epyUserType = userInfo.userType;
                        
                        // For a better understanding of how the white label stuff works, refer to the comments at the top of misc/WhiteLabel.js
                        //
                        // white label organization name change
                        // ----------------
                        // as of 9/7/24 we are currently not really using organization name.  So right now, the only organization in use is 'EmPowerYu'. 
                        // We display the orgname at the top of each page.  This presents a problem because displaying the current orgname (empoweryu)
                        // at the top of the page, doesn't make sense for an AI-Caring user.
                        //
                        // The following code is a hack to get around the problem.
                        // 
                        // The old code displayed the current organization (which is obtained by this endpoint) in the header of the page.  To make
                        // things more whitelabel friendly, the code below forces the orgname to the white label name if the current whiteLabelData object
                        // does not correspond to a name in the whiteLabelList (list of supported white label brands).  If it is not in the list, we use
                        // the organization name passed from the back end.  Otherwise we change the orgName to the white label name (from the whiteLabelData object)
                        //
                        epyOrganizationName = userInfo.organizationName;
                        const orgName = WhiteLabel.getWhiteLabelString()
                        if (WhiteLabelList.findIndex((name) => { return name === orgName})!== -1) {
                            epyOrganizationName = WhiteLabel.getWhiteLabelData(orgName).name;
                        }

                        let inactivityTimeout = userInfo.inactivity_timeout;


                        delete userInfo.timestamp;
                        delete userInfo.success;
                        LocalStorageAPI.setCaregiverId(username, epyOrganizationName, epyCaregiverId, epyUserType, inactivityTimeout);

                        setUserOptionDefaults( epyUserType);
                        dispatchLoginChangeNotification();
                        setInactivityTimeout(inactivityTimeout);
                        (handler)(userInfo, true);
                    } else {
                        console.log("validateUserCredentials: error validating credentials. username = "+username);
                        (handler)(undefined, false);
                    }
                }
                catch (e) {
                    console.log("validateUserCredentials: error parsing credentials validatio JSON. Err = " + e.message);
                    (handler)(undefined, false);
                }
            })
            .catch(function (error) {
                console.log('validateUserCredentials: ' + error);
                (handler)(undefined, false);
            });

        return true;
    }

    function logout() {
        let url = URLS.logoutUser;
        let caregiverId = epyCaregiverId;
        epyCaregiverId = undefined;
        epyUserType = undefined;

        LocalStorageAPI.resetSessionStorage();

        if (caregiverId) {
            axios.post(url, {caregiverId: caregiverId})
                .then(function () {
                    console.log('logout: successfully logged.');
                })
                .catch(function (error) {
                    console.log('logout: '+error);
            });
        }

        dispatchLoginChangeNotification();
        return true;
    }

    function forceLogout() {
        LocalStorageAPI.resetSessionStorage();
        dispatchLoginChangeNotification();
    }

    function getLoginInfo(id, handler) {
        if (id === undefined && epyCaregiverId === undefined) {
            const caregiverId = LocalStorageAPI.getCaregiverId();
            if (caregiverId !== undefined  || caregiverId === null) {
                epyCaregiverId = caregiverId;
                epyUserType = LocalStorageAPI.getUserType();
                epyOrganizationName = LocalStorageAPI.getOrganizationName();
            }
        }
        if (id && handler) {
            epyloginListeners[id] = handler;
        }

        return { caregiverId: epyCaregiverId, userType: epyUserType, organizationName: epyOrganizationName };
    }

    function setCurrentPatientId(id) {
        LocalStorageAPI.setPatientList(id)
    }

    function getCurrentPatientId() {
        return LocalStorageAPI.getPatientId();
    }

    function getUsername() {
        return LocalStorageAPI.getUsername();
    }

    function getOrganizationName() {
        return LocalStorageAPI.getOrganizationName();
    }

    function dispatchLoginChangeNotification() {
        for (let entry in epyloginListeners) {
            (epyloginListeners[entry]) ({ caregiverId: epyCaregiverId, userType: epyUserType, organizationName: epyOrganizationName });
        }
    }

    return {
        validateUserCredentials: validateUserCredentials,
        getLoginInfo: getLoginInfo,
        setCurrentPatientId,
        getCurrentPatientId,
        getUsername,
        getOrganizationName,
        logout,
        forceLogout
    }
}

let _epyCaregiverServices = EpyCaregiverServices();

let CaregiverServices = {
    validateUserCredentials: function(username, password, handler) {
        return _epyCaregiverServices.validateUserCredentials(username, password, handler);
    },
    getLoginInfo: function(id, handler) {
        return _epyCaregiverServices.getLoginInfo(id, handler);
    },
    getCurrentPatientId: function() {
        return _epyCaregiverServices.getCurrentPatientId();
    },
    setCurrentPatientId: function(id) {
        return _epyCaregiverServices.setCurrentPatientId(id);
    },
    getUsername:function() {
        return _epyCaregiverServices.getUsername();
    },
    getOrganizationName:function() {
        return _epyCaregiverServices.getOrganizationName();
    },
    logout: function() {
        return _epyCaregiverServices.logout();
    },
    forceLogout: () => { _epyCaregiverServices.forceLogout() }
};

export default CaregiverServices;
